import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Select, { components }  from 'react-select'
import DownArrow from '../assets/images/down-arrow.svg'

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={ DownArrow } className='dropdown-arrow' />
      </components.DropdownIndicator>
    )
  );
}

class CalculateForm extends Component {

  state = {
    title: null,
    location: null,
    industry: null,
    companySize: null,
  }

  handleChange = (option, field) => {

    let state = {}
    switch (field && field.action) {
      case 'select-option':
        state[field.name] = option
        this.setState(state, this.updateList)
        break
      default:
        let { name, value } = option.currentTarget
        state[name] = value
        this.setState(state, this.updateList)
        break
    }
  }

  render() {

    let { title, location, industry, companySize } = this.state

    const { titleList, locationList, industryList, companySizeList } = this.props.data.wp.siteOptions.settings

    return (

      <>

        <section className='search'>
          <div className='search__inner'>
            <div className='search__items'>
              <div className='react-form__select react-form__select--title'>

                  <label htmlFor="title">

                    Select title 

                    <Select
                      options={titleList}
                      onChange={this.handleChange}
                      id="title"
                      name="title"
                      value={title}
                      placeholder="Select title"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      components={{ DropdownIndicator }}
                    />

                  </label>

                </div>

                <div className='react-form__select react-form__select--location'>

                  <label htmlFor="location">

                    Select location

                    <Select
                      options={locationList}
                      onChange={this.handleChange}
                      id="location"
                      name="location"
                      value={location}
                      placeholder="Select location"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      components={{ DropdownIndicator }}
                    />

                  </label>

                </div>

                <div className='react-form__select react-form__select--job_type'>

                  <label htmlFor="job_type">

                    Select industry

                    <Select
                      options={industryList}
                      onChange={this.handleChange}
                      id="industry"
                      name="industry"
                      value={industry}
                      placeholder="Select Industry"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      components={{ DropdownIndicator }}
                    />

                  </label>

                </div>

                <div className='react-form__select react-form__select--company-size'>

                  <label htmlFor="company_size">

                  Select company size

                  <Select
                    options={companySizeList}
                    onChange={this.handleChange}
                    id="company-size"
                    name="companySize"
                    value={companySize}
                    placeholder="Select company size"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    components={{ DropdownIndicator }}
                  />

                  </label>

                </div>

              </div>
          </div>
        </section>
        <section className='basic'>
          <div className='basic__inner'>
            { title && industry && location && companySize && 
              <h4>Estimated Salary:&nbsp;
                {(title.value * (1 + industry.value) * (1 + location.value) * (1 + companySize.value)).toLocaleString("en", {
                  style: "currency",
                  currency: "AUD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).replace('A', '')}
              </h4>
            }
          </div>
        </section>
      </>
  
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          siteOptions {
            settings {
              titleList {
                label
                value
              }
              companySizeList {
                label
                value
              }
              industryList {
                value
                label
              }
              locationList {
                value
                label
              }
            }
          }
        }
      }
    `}
    render={data => <CalculateForm data={data} {...props} />}
  />
)