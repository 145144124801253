import React, { Component } from 'react'
import { graphql } from 'gatsby'
import CalculateForm from '../components/calculate'
import RenderBlock from '../utils/render-block'
import he from 'he'
import Seo from '../components/seo'

class JobSearchPage extends Component {

  render() {
    
    let page = this.props.data.wpPage

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />
        { page.acf.components && page.acf.components.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'page'))  }
        <CalculateForm />

        <section className="salary">
          <div className="salary__inner">
            <div className="salary__content">
            
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default JobSearchPage

export const jobQuery = graphql`
  query {
    wpPage(databaseId: {eq: 480}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
        schema {
          raw
        }
      }
      acf {
        components {
          ... on WpPage_Acf_Components_BasicPage {
            fieldGroupName
            title
            subTitle
          }
          ... on WpPage_Acf_Components_TabsMenu {
            fieldGroupName
            items {
              text
              link
              active
            }
          }
        }
      }
    }
  }
`
